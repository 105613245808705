//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.custom-rappid {
	.joint-paper-scroller {
		overflow: hidden;

		.paper-scroller-background {
			overflow: hidden;
		}
	}
}

.paper-work-area {
	background-color: #f5f5f5;
}

.joint-paper {
	&.joint-theme-default {
		background-color: #f5f5f5 !important;
	}
	.joint-tools-layer {
		.joint-tool.joint-theme-default {
			rect {
				display: unset !important;
			}
		}
	}
}

.joint-dialog {
	&.joint-lightbox {
		display: unset;
		overflow-y: unset;
		overflow-x: unset;
	}

	.btn-close {
		box-sizing: unset;
		width: unset;
		height: unset;
		padding: unset;
		background: unset;
		opacity: unset;
	}
}

.ant-btn,
.ant-segmented{
	svg { vertical-align: baseline; }
}

.ant-popover {
	z-index: 10000 !important;
}

.wow--group-dropdown-btn {
	.ant-btn-compact-first-item {
		border-start-start-radius: 0;
		border-end-start-radius: 0;
		margin-inline-start: -1px;
	}
}
