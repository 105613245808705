.col-md-3 {
  &.centered-menu-buttons {
    display: flex;
    justify-content: center;
    align-items: center;

    &:last-of-type {
      display: flex;
      justify-content: end;
      align-items: center;
    }
  }
}